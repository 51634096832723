import "./style";
import "./bulma";
// import "bulma/css/bulma.min.css";

import { StoreContainer } from "./store";

import Main from "./components/main";
import { IntlProvider } from "preact-i18n";

const Container = () => {
  const store = StoreContainer.useContainer();
  const { localTexts } = store;
  return (
    <IntlProvider definition={localTexts}>
      <Main />
    </IntlProvider>
  );
};

const App = () => {
  return (
    <StoreContainer.Provider>
      <Container />
    </StoreContainer.Provider>
  );
};

export default App;
