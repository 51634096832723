import { useState, useEffect } from "preact/hooks";
import { createContainer } from "unstated-next";
import Worker from "worker-loader!./worker.js";
import * as Comlink from "comlink";
const pgp = Comlink.wrap(new Worker());
import errors from "./components/errors";
import streamSaver from "streamsaver";

// import texts from "./texts.json";
import texts from "json-loader!yaml-loader!./texts.yaml";

const localTexts = () => {
  const short = navigator.language.split(/\-/g)[0];
  const code = short === "zh" ? navigator.language : short;
  return texts[code] || texts["en"];
};

export const useStore = () => {
  const [initCompleted, setInitCompleted] = useState(false);
  const [keyPair, setKeyPair] = useState({});
  const [accessToken, setAccessToken] = useState(null);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [password, setPassword] = useState(null);
  const [signedUp, setSignedUp] = useState(false);
  const [publicKeyFileId, setPublicKeyFileId] = useState(null);
  const [recipientFileIds, setRecipientFileIds] = useState([]);
  const [recipients, setRecipients] = useState({});
  const [isRestoring, setRestoring] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [mode, setMode] = useState("home");
  const [fileInfo, setFileInfo] = useState({});
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [downloadingProgress, setDownloadingProgress] = useState(0);
  const [error, setError] = useState(null);
  const [browserId, setBrowserId] = useState(null);

  // useEffect(() => {
  //   console.log(keyPair);
  // }, [JSON.stringify(keyPair)]);

  useEffect(() => {
    const NAME = `dear-${location.origin}`;

    const browserId = localStorage.getItem(NAME) || crypto.randomUUID();

    console.log(browserId);
    localStorage.setItem(NAME, browserId);
    setBrowserId(browserId);
  }, []);

  useEffect(() => {
    if (
      mode === "receiver" &&
      fileId &&
      accessToken &&
      password &&
      keyPair.publicKeyArmored
    ) {
      console.log("ready");
      (async () => {
        await pgp.getFileInfo({ fileId, password, accessToken });
        // console.log(await pgp.fileInfo);
        setFileInfo(await pgp.fileInfo);
      })();
    }
  }, [fileId, password, accessToken, keyPair.publicKeyArmored]);

  useEffect(() => {
    if (recipientFileIds.length === 0 || accessToken === null) {
      return;
    }
    (async () => {
      console.log(recipientFileIds.length, accessToken);
      await pgp.fetchRecipientPublicKeys({ recipientFileIds, accessToken });
      setRecipients(await pgp.recipients);
      // const recipientPublicKeys = await Promise.all(
      //   recipientFileIds.map(async () => {})
      // );
      // console.log(recipientPublicKeys);
    })();
  }, [accessToken, recipientFileIds]);

  useEffect(() => {
    if (email) {
      (async () => {
        await pgp.checkSignedUp({ email });
        setSignedUp(await pgp.signedUp);
      })();
    }
  }, [email]);

  useEffect(() => {
    if (email && password && name && accessToken) {
      setError(null);
      setKeyPair({});
      (async () => {
        setRestoring(true);
        await pgp.restore({
          password,
          email,
          accessToken,
          name,
        });
        setRestoring(false);

        const keyPair = await pgp.keyPair;

        if (!keyPair) {
          setError(errors.WRONG_PASSWORD);
          return;
        }

        setKeyPair(keyPair);
        setSignedUp(await pgp.signedUp);
        setPublicKeyFileId(await pgp.publicKeyFileId);
      })();
    } else if (email && name && accessToken) {
      setError(errors.WRONG_PASSWORD);
    }
  }, [password, name, email, accessToken]);

  window.onSignIn = async (googleUser) => {
    if (
      googleUser &&
      googleUser.getBasicProfile() &&
      googleUser.getBasicProfile().getEmail()
    ) {
      setEmail(googleUser.getBasicProfile().getEmail());
      setName(googleUser.getBasicProfile().getName());
    }

    if (gapi.auth2.getAuthInstance()) {
      gapi.auth2.getAuthInstance().isSignedIn.listen((isSignedIn) => {
        setInitCompleted(isSignedIn);
      });
    }

    await gapi.load("client", {
      callback: async () => {
        gapi.client.init({
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
          ],
        });
        const { access_token } = gapi.client.getToken();

        setAccessToken(access_token);
        setInitCompleted(true);
      },
    });
  };

  const uploadWithFile = async ({ file }) => {
    if (accessToken && email && name && password) {
      setUploadingProgress(0.0);
      setFileId(null);
      await pgp.uploadWithFile(
        {
          accessToken,
          email,
          name,
          password,
          file,
        },
        Comlink.proxy((readingProgress) => {
          console.log(readingProgress);
        }),
        Comlink.proxy((uploadingProgress) => {
          setUploadingProgress(uploadingProgress);
        })
      );
      setFileId(await pgp.fileId);

      // const el = document.createElement("a");
      // el.href = await pgp.contentUrl;
      // el.download = await pgp.originalFileName;
      // el.click();
    }
  };
  // const upload = async ({ url, type, fileName }) => {
  //   if (accessToken && url && email && name && password) {
  //     await pgp.upload({
  //       accessToken,
  //       url,
  //       email,
  //       type,
  //       name,
  //       fileName,
  //       password
  //     });

  //     const el = document.createElement("a");
  //     el.href = await pgp.contentUrl;
  //     el.download = await pgp.originalFileName;
  //     el.click();
  //   }
  // };
  const download = async () => {
    if (
      mode === "receiver" &&
      fileId &&
      accessToken &&
      password &&
      keyPair.publicKeyArmored
    ) {
      setDownloadingProgress(0);
      // await pgp.getFileInfo({ fileId, accessToken, password });
      // const fileInfo = await pgp.fileInfo;
      const fileStream = await streamSaver.createWriteStream(fileInfo.fileName);
      const writer = await fileStream.getWriter();

      await pgp.downloadWithStream(
        { fileId, accessToken, password },
        Comlink.proxy((downloadingProgress) => {
          setDownloadingProgress(downloadingProgress);
        }),
        Comlink.proxy((value) => {
          if (value === null) {
            console.log("completed");
            writer.close();
            console.log("completed");
          } else {
            writer.write(value);
          }
        })
      );
      // await pgp.download(
      //   { fileId, accessToken, password },
      //   Comlink.proxy(downloadingProgress => {
      //     console.log(downloadingProgress);
      //     setDownloadingProgress(downloadingProgress);
      //   })
      // );

      // const el = document.createElement("a");
      // el.href = await pgp.contentUrl;
      // el.download = await pgp.originalFileName;
      // el.click();
    }
  };
  const init = async () => {
    // loadGapi({ action: "SIGNIN" });

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("differ", "");
    script.src = "https://apis.google.com/js/platform.js";
    document.head.append(script);
  };
  const cancel = () => {
    pgp.cancel();
    setUploadingProgress(0.0);
    setDownloadingProgress(0.0);
  };
  return {
    keyPair,
    init,
    initCompleted,
    email,
    password,
    setPassword,
    password,
    signedUp,
    publicKeyFileId,
    setRecipientFileIds,
    recipients,
    isRestoring,
    uploadWithFile,
    fileId,
    setFileId,
    mode,
    setMode,
    fileInfo,
    download,
    uploadingProgress,
    downloadingProgress,
    localTexts: localTexts(),
    error,
    cancel,
    browserId,
  };
};
export const StoreContainer = createContainer(useStore);
