import { useEffect, useState, useRef, useCallback } from "preact/hooks";
import style from "./style";
import { StoreContainer } from "../store";
import {
  Textarea,
  TextInput,
  Field,
  Button,
  Control,
  Card,
  Notification,
  Progress,
  Modal,
  Tabs,
  Panel,
  Tag,
} from "preact-bulma";
import { Row, Col } from "react-simple-flex-grid";
import "react-simple-flex-grid/lib/main.css";
import { Text, MarkupText } from "preact-i18n";

const Main = () => {
  const [pickedFileName, setPickedFileName] = useState(null);
  const [fileToBeUploaded, setFileToBeUploaded] = useState(null);
  const [started, setStarted] = useState(false);
  const [downloadStarted, setDownloadStarted] = useState(false);
  const [order, setOrder] = useState([1, 0, 2]);
  const [errorComponent, setErrorComponent] = useState(null);
  const [noAction, setNoAction] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [modalTitle, setModalTitle] = useState(
    <Text id="fileUploadURL">URL for Sending a File</Text>
  );

  const [message, setMessage] = useState("");
  const [textareaContent, setTextareaContent] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  const [recipientsModal, setRecipientsModal] = useState(false);
  const [pickedFileModal, setPickedFileModal] = useState(false);
  const [uploadCompleteModal, setUploadCompleteModal] = useState(false);

  const [colSize, setColSize] = useState(4);

  const passwordConfirm = useRef(null);
  const passwordCurrent = useRef(null);
  const store = StoreContainer.useContainer();
  const {
    keyPair,
    init,
    initCompleted,
    email,
    password,
    setPassword,
    signedUp,
    publicKeyFileId,
    setRecipientFileIds,
    recipients,
    isRestoring,
    uploadWithFile,
    fileId,
    setFileId,
    mode,
    setMode,
    fileInfo,
    download,
    uploadingProgress,
    downloadingProgress,
    error,
    cancel,
    browserId,
  } = store;

  useEffect(() => {
    switch (tabIndex) {
      case 0:
        setTextareaContent(message);
        break;
      case 1:
        if (mode === "home") {
          setTextareaContent(`${location.origin}/s/${publicKeyFileId}`);
        }
        if (mode === "sender") {
          setTextareaContent(`${location.origin}/r/${fileId}`);
        }
        break;
      case 2:
        setTextareaContent(publicKeyFileId);
        break;
      case 3:
        setTextareaContent(keyPair.publicKeyArmored);
        break;
      default:
        setTextareaContent(message);
    }
  }, [message, tabIndex]);

  const textareaRef = useCallback(
    (node) => {
      if (!node) {
        return;
      }
      if (!publicKeyFileId && mode === "home") {
        return;
      }
      if (!fileId && mode === "sender") {
        return;
      }
      setMessage(node.innerText);
    },
    [publicKeyFileId, fileId, mode]
  );

  useEffect(() => {
    location.pathname.replace(
      /^\/(s|r)\/([^\/]+$)/,
      (_, sendOrReceive, fileIds) => {
        if (fileIds) {
          switch (sendOrReceive) {
            case "s":
              setColSize(6);
              setMode("sender");
              setRecipientFileIds(fileIds.split(/,/));
              setOrder([0, 1, 2]);
              break;
            case "r":
              setColSize(4);
              setMode("receiver");
              setOrder([1, 0, 2]);
              setFileId(fileIds);
              break;
            default:
              break;
          }
        }
      }
    );
  }, []);

  useEffect(() => {
    if (!initCompleted) {
      (async () => {
        await init();
      })();
    }
  }, []);

  useEffect(() => {
    if (password) {
      setNoAction(false);
    }
  }, [password]);

  useEffect(() => {
    if (!fileId) {
      return;
    }
    setPickedFileModal(false);
    if (mode === "sender") {
      setUploadCompleteModal(true);
    }
  }, [fileId]);

  const checkPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // const passwordValue =
    //   password.current.value ||
    //   passwordCurrent.current.value ||
    //   (document.querySelector("[name=password]") &&
    //     document.querySelector("[name=password]").value) ||
    //   (document.querySelector("[name=passwordCurrent]") &&
    //     document.querySelector("[name=passwordCurrent]").value);

    const passwordValue =
      (document.querySelector("[name=password]") &&
        document.querySelector("[name=password]").value) ||
      (document.querySelector("[name=passwordCurrent]") &&
        document.querySelector("[name=passwordCurrent]").value) ||
      "empty";

    setPassword(passwordValue);
    setOpenModal(true);
  };

  const filePicked = (e) => {
    const { files } = e.target;
    if (files.length === 0) {
      return; // @TODO
    }

    setFileToBeUploaded(files[0]);
    setStarted(false);
    setPickedFileModal(true);
    setFileId(null);
    e.target.value = null;

    // console.log(fileToBeUploaded);

    // setFileInfoToBeUploaded({ file: files[0] });
    // const { name: fileName } = files[0];
    // setPickedFileName(fileName);
    // uploadWithFile({ file: files[0] });
  };

  const startUpload = async (e) => {
    if (!fileToBeUploaded) {
      return; // @TODO
    }
    setStarted(false);
    setStarted(true);
    await uploadWithFile({ file: fileToBeUploaded });
  };

  const startDownload = async (e) => {
    setDownloadStarted(false);
    setDownloadStarted(true);
    await download();
  };

  const reloadRecipients = (e) => {
    setRecipientFileIds(
      document.querySelector("#recipientIds").value.split(/\n/)
    );
  };

  return (
    <div class="hero" id="app">
      <div class="hero-body">
        <div class="container">
          <div ref={textareaRef} class={style.source}>
            {mode === "home" && (
              <Text
                id="fileUploadMessage"
                fields={{ URL: `${location.origin}/s/${publicKeyFileId}` }}
              ></Text>
            )}
            {mode === "sender" && (
              <Text
                id="fileReceiveMessage"
                fields={{ URL: `${location.origin}/r/${fileId}` }}
              ></Text>
            )}
          </div>
          {!noAction && error && (
            <Notification color="danger" persistent={true}>
              {error}
            </Notification>
          )}
          <Modal.Card
            title={<Text id="fileToBeDownloaded">File to be Downloaded</Text>}
            active={
              initCompleted &&
              mode === "receiver" &&
              keyPair.publicKeyArmored &&
              openModal
            }
            onClose={(e) => {
              setOpenModal(false);
            }}
          >
            <Modal.CardBody>
              <Field label={<Text id="senderInfo">Sender</Text>}>
                <Control iconsLeft="fas fa-user">
                  <TextInput value={fileInfo.sender} disabled />
                </Control>
              </Field>
              <Field
                label={<Text id="downloadFileName">File Name</Text>}
                help={
                  <Text id="downloadFileNameDescription">
                    The file will be downloaded with this name.
                  </Text>
                }
              >
                <Control iconsLeft="fas fa-file">
                  <TextInput value={fileInfo.fileName} disabled />
                </Control>
              </Field>
              <Field label={<Text id="lastModified">Last Modified</Text>}>
                <Control iconsLeft="fas fa-clock">
                  <TextInput
                    value={
                      fileInfo.lastModified &&
                      new Date(fileInfo.lastModified).toLocaleString()
                    }
                    disabled
                  />
                </Control>
              </Field>
              <Field label={<Text id="mimeType">File Type</Text>}>
                <Control iconsLeft="fas fa-file-alt">
                  <TextInput value={fileInfo.mimeType} disabled />
                </Control>
              </Field>
              <Field
                label={<Text id="originalSize">Original Size</Text>}
                help={
                  <Text id="originalSizeDescription">
                    The original size of the file.
                  </Text>
                }
              >
                <Control iconsLeft="fas fa-save">
                  <TextInput
                    value={
                      fileInfo.originalSize
                        ? `${fileInfo.originalSize.toLocaleString()} bytes`
                        : ""
                    }
                    disabled
                  />
                </Control>
              </Field>
              <Field
                label={<Text id="downloadSize">Download Size</Text>}
                help={
                  <Text id="downloadSizeDescription">
                    The size to be downloaded. This size is larger than the
                    original size because of the encryption.
                  </Text>
                }
              >
                <Control iconsLeft="fas fa-cloud-download-alt">
                  <TextInput
                    value={
                      fileInfo.downloadSize
                        ? `${fileInfo.downloadSize.toLocaleString()} bytes`
                        : ""
                    }
                    disabled
                  />
                </Control>
              </Field>
              <Field
                help={
                  <Text id="startDownloadHelp">
                    Click the button if you have no issue with the information
                    above.
                  </Text>
                }
              >
                <Button
                  fullWidth
                  color="info"
                  outlined={true}
                  onClick={startDownload}
                  rounded
                >
                  <Text id="startDownload">Start Download</Text>
                </Button>
              </Field>
              <div class={downloadStarted ? style.block : style.transparent}>
                <Field
                  label={
                    <Text id="downloadingProgress">Downloading Progress</Text>
                  }
                >
                  <Progress
                    max={100}
                    class={downloadingProgress == 0 ? style.block : style.none}
                  />
                  <Progress
                    value={downloadingProgress * 100}
                    max={100}
                    class={downloadingProgress != 0 ? style.block : style.none}
                  />
                </Field>
              </div>
            </Modal.CardBody>
          </Modal.Card>
          <Modal.Card
            title={<Text id="loadedRecipients">Loaded Recipients</Text>}
            active={recipientsModal}
            onClose={(e) => {
              setRecipientsModal(false);
            }}
          >
            <Modal.CardBody>
              <Field
                help={
                  <Text id="loadedRecipientsDescription">
                    Check if the recipients' emails are true. The text in the
                    boxe is the PGP PUBLIC KEY of the recipient.
                  </Text>
                }
              >
                <Panel.Panel>
                  {Object.keys(recipients).map((key) => {
                    const { publicKeyText, owner } = recipients[key];
                    return (
                      <Panel.Block active icon="fas fa-user">
                        {owner.displayName} &lt;{owner.emailAddress}
                      </Panel.Block>
                    );
                  })}
                </Panel.Panel>
              </Field>
              <label for="file">
                <div class={style.button}>
                  <Button
                    disabled={!keyPair.publicKeyArmored}
                    fullWidth
                    color="info"
                    outlined={false}
                    rounded
                  >
                    <Text id="pickFile">Pick File to Upload</Text>
                  </Button>
                </div>
                <input
                  id="file"
                  type="file"
                  onChange={filePicked}
                  disabled={!keyPair.publicKeyArmored}
                  style={{ display: "none" }}
                  multiple={false}
                  fullWidth
                ></input>
              </label>
            </Modal.CardBody>
          </Modal.Card>
          <Modal.Card
            title={<Text id="pickedFile">File Picked</Text>}
            active={pickedFileModal}
            onClose={(e) => {
              setPickedFileModal(false);
              cancel();
            }}
          >
            <Modal.CardBody>
              <div class={fileToBeUploaded ? style.block : style.none}>
                <Field label={<Text id="fileName">File Name</Text>}>
                  <Control iconsLeft="fas fa-user">
                    <TextInput
                      value={fileToBeUploaded && fileToBeUploaded.name}
                      disabled
                    />
                  </Control>
                </Field>
                <Field label={<Text id="lastModified">Last Modified</Text>}>
                  <Control iconsLeft="fas fa-clock">
                    <TextInput
                      value={
                        fileToBeUploaded &&
                        new Date(fileToBeUploaded.lastModified).toLocaleString()
                      }
                      disabled
                    />
                  </Control>
                </Field>
                <Field label={<Text id="fileSize">Size</Text>}>
                  <Control iconsLeft="fas fa-save">
                    <TextInput
                      value={
                        fileToBeUploaded &&
                        fileToBeUploaded.size.toLocaleString() + " bytes"
                      }
                      disabled
                    />
                  </Control>
                </Field>
                <Button
                  disabled={publicKeyFileId === null}
                  onClick={startUpload}
                  fullWidth
                  color="info"
                  outlined={false}
                  rounded
                >
                  {<Text id="startUpload">Start Upload</Text>}
                </Button>
              </div>
              <div class={started ? style.block : style.transparent}>
                <Field
                  label={<Text id="uploadingProgress">Upload Progress</Text>}
                >
                  <Progress
                    max={100}
                    class={uploadingProgress == 0 ? style.block : style.none}
                  />
                  <Progress
                    value={uploadingProgress * 100}
                    max={100}
                    class={uploadingProgress != 0 ? style.block : style.none}
                  />
                </Field>
              </div>
            </Modal.CardBody>
          </Modal.Card>
          <Modal.Card
            title={<Text id="uploadedFile">Message for Recipients</Text>}
            active={uploadCompleteModal}
            onClose={(e) => {
              setUploadCompleteModal(false);
            }}
          >
            <Modal.CardBody>
              <Tabs.Tabs align="center">
                <Tabs.Tab
                  icon="far fa-file-alt"
                  active={tabIndex === 0}
                  onClick={(e) => {
                    setTabIndex(0);
                  }}
                >
                  <Text id="messageTemplate">Message</Text>
                </Tabs.Tab>
                <Tabs.Tab
                  icon="far fa-file-alt"
                  active={tabIndex === 1}
                  onClick={(e) => {
                    setTabIndex(1);
                  }}
                >
                  <Text id="urlOnly">URL</Text>
                </Tabs.Tab>
              </Tabs.Tabs>
              <Field
                help={
                  <Text id="receivingDescription">
                    Send this message to recipients.
                  </Text>
                }
              >
                <Control>
                  <Textarea readOnly id="urlForReceiving">
                    {textareaContent}
                  </Textarea>
                </Control>
              </Field>
              <Button
                disabled={publicKeyFileId === null}
                onClick={async (e) => {
                  const copyText = document.querySelector("#urlForReceiving");
                  copyText.select();
                  document.execCommand("copy");
                }}
                fullWidth
                color="info"
                outlined={false}
                rounded
              >
                <Text id="clickToCopy">Click to Copy</Text>
              </Button>
            </Modal.CardBody>
          </Modal.Card>
          <Modal.Card
            title={modalTitle}
            active={
              initCompleted &&
              mode === "home" &&
              keyPair.publicKeyArmored &&
              openModal
            }
            onClose={(e) => {
              setOpenModal(false);
            }}
          >
            <Modal.CardBody>
              <div class={initCompleted ? style.block : style.none}>
                <div class={mode === "home" ? style.block : style.none}>
                  <div
                    class={keyPair.publicKeyArmored ? style.block : style.none}
                  >
                    <div>
                      <Tabs.Tabs align="center">
                        <Tabs.Tab
                          icon="far fa-file-alt"
                          active={tabIndex === 0}
                          onClick={(e) => {
                            setTabIndex(0);
                          }}
                        >
                          <Text id="messageTemplate">Message</Text>
                        </Tabs.Tab>
                        <Tabs.Tab
                          icon="far fa-file-alt"
                          active={tabIndex === 1}
                          onClick={(e) => {
                            setTabIndex(1);
                          }}
                        >
                          <Text id="urlOnly">URL</Text>
                        </Tabs.Tab>
                        <Tabs.Tab
                          icon="far fa-file-alt"
                          active={tabIndex === 2}
                          onClick={(e) => {
                            setTabIndex(2);
                          }}
                        >
                          <Text id="idOnly">ID</Text>
                        </Tabs.Tab>
                        <Tabs.Tab
                          icon="far fa-file-alt"
                          active={tabIndex === 3}
                          onClick={(e) => {
                            setTabIndex(3);
                          }}
                        >
                          <Text id="publicKey">PGP Public Key</Text>
                        </Tabs.Tab>
                      </Tabs.Tabs>
                      <Field
                        help={
                          <Text id="urlForSendingFileDescription">
                            Send this URL to the sender.
                          </Text>
                        }
                      >
                        <Control>
                          <Textarea readOnly id="urlForSending">
                            {textareaContent}
                          </Textarea>
                        </Control>
                      </Field>
                      <Button
                        disabled={publicKeyFileId === null}
                        onClick={async (e) => {
                          const copyText =
                            document.querySelector("#urlForSending");
                          copyText.select();
                          document.execCommand("copy");
                        }}
                        fullWidth
                        color="info"
                        outlined={false}
                        rounded
                      >
                        <Text id="copyURL">Click to Copy</Text>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.CardBody>
          </Modal.Card>
          <form method="POST" onSubmit={checkPassword}>
            <Row align="stretch">
              <Col
                xs={12}
                sm={12}
                md={colSize}
                lg={colSize}
                xl={colSize}
                order={order[0]}
                class={style.col}
              >
                <div class={initCompleted ? style.none : style.block}>
                  <div
                    class="g-signin2"
                    data-onsuccess="onSignIn"
                    data-theme="dark"
                  ></div>
                </div>
                <div class={initCompleted ? style.block : style.none}>
                  <Field
                    label={<Text id="account">Your Account</Text>}
                    help={
                      <Text id="accountDescription">
                        Filled automatically with sign-in.
                      </Text>
                    }
                  >
                    <Control iconsLeft="fas fa-user">
                      <TextInput
                        value={`${email}-${browserId}`}
                        disabled
                        type="email"
                        autocomplete="username"
                      />
                    </Control>
                  </Field>
                  <div class={signedUp ? style.block : style.none}>
                    <Field
                      label={
                        <Text id="passwordCurrent">Registered Password</Text>
                      }
                      help={
                        <Text id="passwordCurrentDescription">
                          Password for this service. Only stored in local
                          storage if you use the keychain function provided by
                          OS or browser and never sent to remote server
                          including this service.
                        </Text>
                      }
                    >
                      <Control iconsLeft="fas fa-key">
                        <TextInput
                          ref={passwordCurrent}
                          autoComplete="current-password"
                          type="password"
                          name="passwordCurrent"
                        />
                      </Control>
                    </Field>
                  </div>
                  <div class={!signedUp ? style.block : style.none}>
                    <Field
                      label={<Text id="passwordNew">New Password</Text>}
                      help={
                        <Text id="passwordNewDescription">
                          Password for this service. Only stored in local
                          storage if you use the keychain function provided by
                          OS or browser and never sent to remote server
                          including this service.
                        </Text>
                      }
                    >
                      <Control iconsLeft="fas fa-key">
                        <TextInput
                          ref={passwordCurrent}
                          autoComplete="new-password"
                          type="password"
                          name="password"
                        />
                      </Control>
                    </Field>
                  </div>
                  <div class={!signedUp ? style.block : style.none}>
                    <Field
                      label={
                        <Text id="passwordConfirm">Password for Confirm</Text>
                      }
                    >
                      <Control iconsLeft="fas fa-key">
                        <TextInput
                          ref={passwordCurrent}
                          autoComplete="new-password"
                          type="password"
                          name="passwordConfirm"
                        />
                      </Control>
                    </Field>
                  </div>
                  <div>
                    <Field
                      help={
                        <Text id="restoreButtonDescription">
                          The saved key pair will be decrypted or generated if
                          it cannot be decrypted.
                        </Text>
                      }
                    >
                      <Button
                        fullWidth
                        color="info"
                        outlined={true}
                        onClick={checkPassword}
                        rounded
                        type="submit"
                        class={style.button}
                      >
                        {<Text id="restoreButton">Restore your keypair.</Text>}
                      </Button>
                    </Field>
                  </div>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={colSize}
                lg={colSize}
                xl={colSize}
                order={order[1]}
                class={style.col}
              >
                <div class={initCompleted ? style.block : style.none}>
                  <div
                    class={
                      mode === "sender" && keyPair.publicKeyArmored
                        ? style.block
                        : style.none
                    }
                  >
                    <Field
                      label={<Text id="recipientIds">Recipient IDs</Text>}
                      help={
                        <Text id="recipientIdsDescription">
                          IDs of recipients. IDs can be added in new lines.
                        </Text>
                      }
                    >
                      <Control>
                        <Textarea id="recipientIds" onChange={reloadRecipients}>
                          {Object.keys(recipients)
                            .map((key) => key)
                            .join("\n")}
                        </Textarea>
                      </Control>
                    </Field>
                    <Button
                      fullWidth
                      color="info"
                      outlined={true}
                      onClick={(e) => {
                        setRecipientsModal(true);
                      }}
                      rounded
                    >
                      {<Text id="loadRecipients">Load Recipients</Text>}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Main;
